import 'bootstrap';

const currentYear = document.getElementById('current-year');
currentYear.innerHTML = new Date().getFullYear();

(function ($) {
    "use strict";

    jQuery(document).ready(function ($) {




        $('.menu-open , .offcanvas-overlay').click(function () {

            $('.offcanvas-area , .offcanvas-overlay').addClass('active');

        });
        $('.menu-close , .offcanvas-overlay').click(function () {

            $('.offcanvas-area , .offcanvas-overlay').removeClass('active');

        });


        $('.data-contaciner-selection button').click(function () {

            $('.data-contaciner-selection button').removeClass('active');
            $(this).addClass('active');

        });


 

    });
 
 

}(jQuery));
